import React from 'react'
import Layout from '../components/Layout'
import GiveMain from '../components/GiveMain/GiveMain'
import { Helmet } from 'react-helmet';

const PageTemplate = props => {

  return (
    <Layout>
       <Helmet>
       <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
        <meta charSet="utf-8" />
        <title>Give-Cloudwalk</title>
        <meta name="referrer" content="origin" />
      </Helmet> 
      <GiveMain/>
    </Layout>
  )
}

export default PageTemplate
