import React from 'react';
import * as styles from './GiveMain.module.scss'
import * as style from '../../styles/general.module.scss'
import CtaSection from '../pagebuilderComponents/CtaSection/CtaSection';
import {StaticQuery, graphql} from 'gatsby'
import PortableText from 'react-portable-text';


export default function GiveMain ({ data }) {
    
 var content = []   

    return (<>
            <StaticQuery
                query={graphql`
                    query giveQuery {
                     sanitySiteSettings {
                        giveHeader
                        giveDescription
                        giveVerse
                        _rawGiveMiddleContent
                        giveReference
                        giveBottomHeader
                        giveBottomDescription
                        giveBottomLink
                        }
                    }   
                `}
                    render={data => {
                        const ctaData = {
                            header: data.sanitySiteSettings.giveBottomHeader,
                            ctaContent: data.sanitySiteSettings.giveBottomDescription,
                            ctalabel: 'Donate Now',
                            url: data.sanitySiteSettings.giveBottomLink,
                            headerSize:'medium',
                            bgColor: 'calm',
                        }    
                        content.push(<section className={styles.giveMainWrapper} >
                    
                            <div className={styles.giveTop}>
                                <div className={style.layout}>
                                    <div className={styles.topContent}>
                                        <h1>{data.sanitySiteSettings.giveHeader}</h1>
                                        <h2>{data.sanitySiteSettings.giveDescription}</h2>
                                             <p>{data.sanitySiteSettings.giveVerse}</p>
                                        <span>{data.sanitySiteSettings.giveReference}</span>
                                    </div>
                                    
                                </div>
                            </div>
                                <div className={style.layout}>
                                    <div className={styles.mainContent}>
                                        <div className={styles.formInfo}>
                                            <PortableText content={data.sanitySiteSettings._rawGiveMiddleContent}/>
                                        </div>

                                        <div className={styles.formWrapper}>
                                        <script src="https://donorbox.org/widget.js" paypalExpress="false"></script><iframe title="Cloudwalk Donations" src="https://donorbox.org/embed/cloudwalk-donations" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" ></iframe>
                                        </div>
                                    </div>
                                </div>
                            <CtaSection data={ctaData}/>
                    </section>)
                    
                }}

            />
            {content}

    </>)
}
